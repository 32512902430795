import styled from 'styled-components';

export const CarbonOffsetBanner = styled.img`
  display: block;
  width: 100%;
  height: auto;
  @media (max-width: 1199px) {
    
  }
  @media (max-width: 991px) {
    margin: 0;
  }
  @media (max-width: 767px) {
    margin: 0;
  }
`;

export const AnchorStyle = styled.a`
  display: block;
  width: 100%;
  z-index: 5;
`;